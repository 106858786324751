<template>
    <div class="validView" :style="fail ? 'padding-bottom:53px;' : '' ">
        <van-icon name="cross" size="24" class="closeIcon" @click="close"/>
        <div class="title">{{ uploadInfo.name }}</div>
        <div class="tips">
            <div class="tips_text" v-if="fail">{{ failText }}</div>
            <div class="tips_text">注意：请使用后置摄像头，保证车牌清晰可见</div>
        </div>
        <div class="uploadBox" v-if="uploadInfo && (!uploadInfo.imgList || uploadInfo.imgList.length == 0)">
            <img :src="uploadInfo.picture" mode="" class="tipImg"/>
            <span class="textImg">示例图</span>
            <van-uploader 
                class="uploadBtn"
                capture="camera"
                :after-read="afterRead">
                <img src="https://www.tc-rescue.com/images/tc_app/upload_icon_opc.png" class="uploadImg" alt="">
            </van-uploader>
        </div>
        <div class="uploadBox" v-if="uploadInfo && uploadInfo.imgList && uploadInfo.imgList.length != 0">
            <img class="tipImg" :src="uploadInfo.imgList[0]" alt="">
        </div>
        <div class="footer" v-if="fail">
            <van-uploader 
                class="btn leftBtn"
                capture="camera"
                :after-read="afterRead">
                <div>重新上传</div>
            </van-uploader>
            <div class="line"></div>
            <div class="btn rightBtn" @click="sumitArriveStart">继续执行</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { validStart, validEnd } from '@/utils/uploadImg.js';
import { getGeoLocation } from '@/utils/index';
import { initWatermarkConfigData, generateCanvasImg } from '@/utils/uploadOSS';
import { driverUploadPic, arrivestart, arriveend, licenseOCR, orderPhotoConfig } from '@/apis/checkList';

export default {
    props: {
        orderInfo: {
            type: Object | null,
            default: ''
        },
        orderId: {
            type: String | null,
            default: ''
        }
    },
    data(){
        return {
            fail: false,
            failText: '',
            uploadInfo: '',
            location: '',
            imgFile: ''
        }
    },
    computed: {
        ...mapState({
            'configData': state => state.configData,
            'driverInfo': state => state.driverInfo     //聚合同步技师信息
        })
    },
    mounted(){
        this.initImage()
    },
    methods: {
        async initImage(){
            let status = this.orderInfo.status;
            let uploadImgArr = [];
            let photoConfig = '';
            let uploadInfo = '';

            try{
                let res = await orderPhotoConfig({ id: this.orderInfo.id })
                photoConfig = res.data.data ? res.data.data.data : ''
            }catch(err){

            }
            if(photoConfig){
                uploadImgArr = photoConfig;
            }else{
                let rescueImg = await this.$store.dispatch('getPhotoKeyTemplate', this.orderInfo.type); 
                uploadImgArr = rescueImg ? JSON.parse(JSON.stringify(rescueImg)) : []
            }

            for(let i=0; i<uploadImgArr.length; i++){
                if(uploadImgArr[i].step == 1 && (status == 2 || status == 22)){
                    uploadInfo = uploadImgArr[i];
                    break
                }
                if(uploadImgArr[i].step == 2 && (status == 3 || status == 25)){
                    uploadInfo = uploadImgArr[i];
                    break
                }
            }
            this.uploadInfo = JSON.parse(JSON.stringify(uploadInfo));
        },
        // 定位
        getLocation(){
            getGeoLocation().then((res)=>{
                this.location = res;
            })
        },
        close(){
            this.$emit('close')
        },
        // 上传文件
        async afterRead(files){
            this.$toast.loading({
                message: '努力上传中...',
                forbidClick: true, 
                duration: 0
            })
            let _this = this;
            let file = files.file;
            this.imgFile = file;
            try {
                this.location = await getGeoLocation();
            } catch (error) {
                this.$notify({ 
                    type: 'danger',
                    message: '定位失败，请稍后重试!'
                });
                this.$toast.clear();
                return
            }
            if(!this.location || !this.location.lat || !this.location.lng || !this.location.address){
                this.$notify({ 
                    type: 'danger',
                    message: '定位失败，请稍后重试!'
                });
                this.$toast.clear();
                return
            };
            let reader = new FileReader();
            reader.readAsDataURL(file);
            
            let configPrams = {
                configData: this.configData,
                orderInfo: this.orderInfo,
                location: this.location,
                driverInfo: this.driverInfo,
                keyName: this.uploadInfo.name,
                imgKey: this.uploadInfo.key
            }
            let configData = initWatermarkConfigData(configPrams);       //水印配置文件

            reader.onload = function(theFile) {
                let image = new Image();
                image.src = theFile.target.result;
                image.onload = function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData,
                    }
                    generateCanvasImg(data).then((url)=>{
                        _this.uploadImg(url, configData.watermarkTime)
                    }).catch((err)=>{
                        _this.$notify({ 
                            type: 'danger',
                            message: 'OSS上传失败!'
                        });
                        _this.$toast.clear();
                    })
                }
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url, watermarkTime){
            if(url){
                let data = {
                    order: this.orderId,
                    key: this.uploadInfo.key,
                    ser: 1,
                    url,
                    watermark_time: watermarkTime
                }
                driverUploadPic(data).then((res)=>{
                    if(res.data.code == 1){
                        this.uploadInfo.imgList = [url];
                        this.$toast.clear();
                        if(this.orderInfo.status == 2 || this.orderInfo.status == 22){
							// 到达救援地效验
							this.validImage();
						}else{
							// 到达目的地
                            console.log(this.orderInfo.status, 'this.orderInfo.status')
							this.sumitArriveend();
						}
                    }else{
                        this.$notify({ 
                            type: 'danger',
                            message: res.data.info
                        });
                        this.$toast.clear();
                    }
                }).catch(()=>{
                    this.$notify({ 
                        type: 'danger',
                        message: '上传失败!'
                    });
                    this.$toast.clear();
                })
            }else{
                this.$notify({ 
                    type: 'danger',
                    message: '上传失败!'
                });
                this.$toast.clear();
            }
        },
        // 救援地ocr效验
        validImage(){
            this.$toast.loading({
                message: '识别中...',
                forbidClick: true, 
                duration: 0
            })
            let that = this;
            let file = this.imgFile;
            let formData = new FormData();
            formData.append('file', file);
            licenseOCR(formData).then((res)=>{
                let data = res.data;
                if(data.code == 1){
                    let plate_number = data.data.plate_number;
                    if(plate_number == that.orderInfo.car_no){
                        that.fail = false;
                        that.sumitArriveStart();
                    }else{
                        that.failText = '识别车牌 ' + plate_number + '与订单车牌 ' + that.orderInfo.car_no + '不一致';
                        that.fail = true;
                    }
                }else{
                    that.failText = '未识别到车牌！';
                    that.fail = true;
                }
                this.$toast.clear();
            }).catch(()=>{

            })
        },
        // 到达救援地
        sumitArriveStart(){
            let that = this;
            this.$toast.loading({
                message: '正在操作中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location = that.location;
			let is_arrive_pass = !this.fail ? 1 : this.failText == '未识别到车牌！' ? 3 : 2;
            let data = {
                order: this.orderId,
                address: location.address,
                lnglat: location.lng + ',' + location.lat,
                is_arrive_pass
            }
            arrivestart(data).then((res)=>{
                console.log(res, '到达救援地');
                this.$toast.clear();
                if(res.data.code == 1){
                    this.$emit('success')
                    this.$emit('close')
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
        // 到达目的地
        sumitArriveend(){
            this.$toast.loading({
                message: '正在操作中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location = this.location;
            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let data = {
                order: this.orderId,
                address: location.address,
                lnglat: location.lng + ',' + location.lat
            }
            arriveend(data).then((res)=>{
                console.log(res, '到达目的地');
                this.$toast.clear();
                if(res.data.code == 1){
                    this.$emit('success')
                    this.$emit('close')
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .validView{
        .closeIcon{
            position: absolute;
            top: 16px;
            right: 10px;
        }
        .title{
            padding: 16px 0;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            text-align: center;
            line-height: 22px;
        }
        .tips{
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .tips_text{
                font-size: 12px;
                color: #FF451E;
                line-height: 20px;
            }
        }
        .uploadBox{
            width: 253px;
            height: 191px;
            border-radius: 5px 5px 5px 5px;
            margin: 0 auto 20px;
            position: relative;
            .tipImg{
                width: 100%;
                height: 100%;
            }
            .textImg {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 42px;
                height: 20px;
                background: #357EFE;
                opacity: 1;
                border-radius: 4px 0px 4px 0px;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                line-height: 20px;
            }
            .uploadBtn{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .uploadImg{
                    width: 70px;
                    height: 70px;
                }
            }
        }
        .footer{
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 53px;
            border-top: 1px solid #E4E4E4;
            .btn{
                height: 100%;
                flex: 1;
                font-size: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .leftBtn{
                color: #999999;
            }
            .rightBtn{
                color: #357EFE;
            }
            .line{
                width: 1px;
                height: 18px;
                background: #E4E4E4;
            }
        }
    }
</style>